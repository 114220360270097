import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import RoleFeature from "../components/roleFeature"
import KukeFeature from "../components/kukeFeature"
import CatNav from "../components/catnav"
import UsersSay from "../components/usersSay"
import Banner from "../components/banner"
import MenuItems from "../components/menuItems"
import { HomeSvgs, CasesSvgs, PostListSvgs } from "../components/svgs"
import { CDNBASE } from "../utils/maps"
import advData from "../json/advance.json"
import st from "./css/home.module.scss"
import kukeFeatures from "../json/kuke-features.json"
import roleFeatures from "../json/role-features.json"

const IndexPage = function () {
  let isMobile = false
  if (typeof document !== "undefined") {
    isMobile = document.documentElement.clientWidth <= 1140
  }

  return (
    <Layout cur="/" stickyHeader>
      <SEO
        title="微信约课小程序"
        keywords="舞房约课,瑜伽约课,早教约课,运动约课,艺术约课,课表排课,学员约课,老师点评,"
        description="酷课约课系统帮助瑜伽馆、舞蹈工作室、少儿培训机构,高效地进行会员管理、课程/课表/预约管理、员工管理、在线营销、在线交易以及统计分析，智慧经营，提升运营效率，增强市场竞争力。"
      />
      <HomeSvgs />
      <CasesSvgs />
      <PostListSvgs />

      <div
        className={st.banner}
        style={{ backgroundImage: `url(${CDNBASE}/home/banner/1.png)` }}
      >
        <h1>为各类培训机构量身定制的会员管理、在线营销、约课系统</h1>
        <div className={st.link}>
          <Link
            className="btn-pri btn-large"
            target="_blank"
            to="https://kukecloud.com/adm/"
          >
            开始免费试用
          </Link>
        </div>
        <div className={st.qrcode}>
          <img
            alt="酷课公众号"
            src={CDNBASE + "/src/qrcode/user-intro-ying.png"}
          />
          <h5>长按识别，开始免费试用</h5>
        </div>
        <div
          className={st.ill}
          style={{ backgroundImage: `url(${CDNBASE}/home/banner/1.1.png?001)` }}
        ></div>
      </div>
      <MenuItems
        icon="kuke_features/icons/roles.png?112"
        iconSize={isMobile ? 122 : 144}
        list={roleFeatures.map(v => ({ id: v.icon, name: v.title }))}
        title="酷课约课系统怎么用？"
        // genLink={v => `/#${v.icon}-${v}`}
        isMobile={isMobile}
        styles={{ flexWrap: "nowrap" }}
      />
      <MenuItems
        icon="kuke_features/icons/features.png"
        iconSize={isMobile ? 84 : 134}
        list={kukeFeatures.map(v => ({ id: v.icon, name: v.title }))}
        title="酷课约课系统有哪些功能？"
        isMobile={isMobile}
        idPre="intro-"
      />
      <KukeFeature isMobile={isMobile} />
      <RoleFeature isMobile={isMobile} />

      <div>
        {advData.slice(0, 2).map((v, i) => (
          <Banner key={i} v={v} i={i} />
        ))}
      </div>

      <UsersSay />

      <div>
        <h2 className="f-tc f-mt-3">哪些行业在使用酷课约课系统？</h2>
        <CatNav urlBase="/cases" />
      </div>
    </Layout>
  )
}

export default IndexPage
